<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <div>
          <h4>Yeni Varlık</h4>
        </div>
      </div>
    </template>

    <b-form class="form">
      <div class="row">
        <!-- <div class="col-12">
        <div class="card-sub-header">Varlık Bilgileri</div>
      </div> -->

        <!-- varlık ad -->
        <div class="col-12">
          <div class="form-group">
            <label for="ad">Varlık Adı </label>
            <input type="text" v-model="title" id="ad" required  name="name" class="form-control" />
          </div>
        </div>
        
        <!-- varlık tipi -->
        <div class="form-group col-6">
          <label for="izle">Varlık Tipi</label>
          <select name="type" required v-model="type" id="izle" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="Ev">Ev</option>
            <option value="Daire">Daire</option>
            <option value="Villa">Villa</option>
            <option value="İş Yeri">İş Yeri</option>
            <option value="Arsa">Arsa</option>
          </select>
        </div>
        <div class="form-group col-6">
          <label for="saleType">Satış Tipi</label>
          <select name="saleType" required v-model="saleType" id="saleType" class="form-control">
            <option value="" selected>Seçiniz</option>
            <option value="amount">Miktar</option>
            <option value="percent">Parçalı</option>
           
          </select>
        </div>
        <!-- oda sayısı -->
        <!-- adres -->
        <div class="form-group col-12">
          <label for="address">Adres</label>
          <textarea
            name="address"
            id="address"
            class="form-control"
            rows="3"
            v-model="address"
          ></textarea>
        </div>
        <div class="form-group col-12">
          <label for="explanation">Açıklama</label>
          <textarea
            name="explanation"
            id="explanation"
            class="form-control"
            rows="3"
            v-model="explanation"
          ></textarea>
        </div>
        <!-- fiyat -->
        <div class="form-group col-4">
          <label for="price">Fiyat</label>
          

         <input type="text" name="price" v-model="price" id="price" class="form-control" @input="formatAmount"/>
        </div>
        <!-- min fiyat -->
        <div class="form-group col-4">
          <label for="minSale">Minimum Teklif</label>
          <input type="text" name="minSale" id="minSale" v-model="minSale" class="form-control" @input="formatMinSale" />
        </div>
        <!-- fiyat -->
        <div class="form-group col-4">
          <label for="maxSale">Maksimum Teklif</label>
          <input type="text" name="maxSale" id="maxSale" v-model="maxSale" class="form-control" @input="formatMaxSale"/>
        </div>
        <!-- özellikler -->
        <!-- Başlangıç Tarihi -->
        <div class="form-group col-6">
          <label for="starOf">Başlangıç Tarihi</label>
          <input type="datetime-local" name="starOf" id="starOf" v-model="startOf" class="form-control" />
        </div>
        <!-- Bitiş Tarihi -->
        <div class="form-group col-6">
          <label for="endOf">Bitiş Tarihi</label>
          <input type="datetime-local" name="endOf" id="endOf" v-model="endOf" class="form-control" />
        </div>
      </div>
    </b-form>

    <button class="saveButton mx-auto mt-5" @click="sendForm()">Kaydet</button>
  </Card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MaskedInput from 'vue-masked-input';
import { mapGetters } from "vuex";

export default {
  components: {
    MaskedInput,
  },

  data() {
    return {
      title: "",
      type: "",
      price: "",
      saleType: "",
      minSale: "",
      maxSale: "",
      startOf: "",
      endOf: "",
      explanation: "",
      address: "",
      
      
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Varlıklar / Yeni", route: "/properties/new" },
    ]);
  },
  methods: {
    formatAmount() {
      this.price = this.price.replace(/\D/g, ''); // Sayı dışındaki karakterleri kaldır
      this.price = parseInt(this.price).toLocaleString('en-US'); // Sayıyı formatla
    },
    formatMinSale() {
      // Girilen değeri nokta eklenmiş hale getir
      this.minSale = this.minSale.replace(/\D/g, ''); // Sayı dışındaki karakterleri kaldır
      this.minSale = parseInt(this.minSale).toLocaleString('en-US'); // Sayıyı formatla
    },
    formatMaxSale(){
      this.maxSale = this.maxSale.replace(/\D/g, ''); // Sayı dışındaki karakterleri kaldır
      this.maxSale = parseInt(this.maxSale).toLocaleString('en-US'); // Sayıyı formatla

    },
    removeDots(value) {
      return parseInt(value.replace(/\,/g, ''));
    },
    dayDiff(value){
      var bugun = new Date();

      // Karşılaştırılacak tarihi belirle (örneğin, "YYYY-MM-DD" formatında)
      var karsilastirilacakTarih = new Date(value);

      // Tarih farkını hesapla
        var farkMillisaniye = karsilastirilacakTarih - bugun ;
        var farkGun = Math.ceil(farkMillisaniye / (1000 * 60 * 60 * 24));
      return farkGun
    },
    sendForm(){
      const data = {
        title : this.title,
        type  : this.type,
        price : this.removeDots(this.price),
        saleType :  this.saleType,
        minSale : this.removeDots(this.minSale),
        maxSale : this.removeDots(this.maxSale),
        endOf : this.dayDiff(this.endOf),
        startOf : this.dayDiff(this.startOf),
        explanation : this.explanation,
        address : this.address,
        userId : this.currentUser._id,
        attributes:[],
        city:"Kayseri"
      }
      this.$ApiService
          .post('property/setProperty', data)
          .then((response) => {
            this.$generateNotification(this,"success","Varlık Başarıyla Eklenmiştir.")
          })
          .catch((err) => {
            this.$generateNotification(this,"error","Varlık Eklenirken Bir Hata İle Karşılaşıldı.")
          });
    }
  },
  computed:{
    ...mapGetters(["myUser","currentUser"])
  },

};
</script>
